import { createSlice } from "@reduxjs/toolkit";

export const skillProfiles = createSlice({
  name: "skillProfiles",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init skillprofiles
     * @param {ReduxState} state
     * @return {void}
     */
    initSkillprofiles: (state) => {
      state.init = true;
    },
    /**
     * Deinit skillprofiles
     * @param {ReduxState} state
     * @return {void}
     */
    deinitSkillprofiles: (state) => {
      state.init = false;
    },
    /**
     * Set skillprofiles
     * @param {ReduxState} state
     *  @param {Action} action
     * @return {void}
     */
    setSkillProfiles: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initSkillprofiles,
  deinitSkillprofiles,
  setSkillProfiles,
} = skillProfiles.actions;

export default skillProfiles.reducer;
