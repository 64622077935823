import { Backdrop } from "@mui/material";
import logoLoader from "../images/logo-icon.svg";

/**
 * LogoAnimStart component
 * @constructor
 */
export const LogoAnimStart = () => {
  return (
    <Backdrop className="loader logoAnim" open={true}>
      <img src={logoLoader} alt='logo icon loader' />
    </Backdrop>
  );
};
