import { createSlice } from "@reduxjs/toolkit";

export const expenseTypes = createSlice({
  name: "expenseTypes",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init expenseTypes
     * @param {ReduxState} state
     * @return {void}
     */
    initExpenseTypes: (state) => {
      state.init = true;
    },
    /**
     * Deinit expenseTypes
     * @param {ReduxState} state
     * @return {void}
     */
    deinitExpenseTypes: (state) => {
      state.init = false;
    },
    /**
     * Set expenseTypes
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setExpenseTypes: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initExpenseTypes,
  deinitExpenseTypes,
  setExpenseTypes,
} = expenseTypes.actions;

export default expenseTypes.reducer;
