import { createSlice } from "@reduxjs/toolkit";

export const projects = createSlice({
  name: "projects",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init projects
     * @param {ReduxState} state
     * @return {void}
     */
    initProjects: (state) => {
      state.init = true;
    },
    /**
     * Deinit projects
     * @param {ReduxState} state
     * @return {void}
     */
    deinitProjects: (state) => {
      state.init = false;
    },
    /**
     *  Set projects
     * @param {ReduxState} state
     * @param {Action} action
     */
    setProjects: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initProjects,
  deinitProjects,
  setProjects,
} = projects.actions;

export default projects.reducer;
