import { createSlice } from "@reduxjs/toolkit";

export const skills = createSlice({
  name: "skills",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init skills
     * @param {ReduxState} state
     * @return {void}
     */
    initSkills: (state) => {
      state.init = true;
    },
    /**
     * Deinit skills
     * @param {ReduxState} state
     * @return {void}
     */
    deinitSkills: (state) => {
      state.init = false;
    },
    /**
     * Set skills
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setSkills: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initSkills,
  deinitSkills,
  setSkills,
} = skills.actions;

export default skills.reducer;
