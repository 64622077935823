import { createSlice } from "@reduxjs/toolkit";

export const currencies = createSlice({
  name: "currencies",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Set currencies
     * @param {ReduxState} state
     * @param {Action} action
     */
    setCurrencies: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  setCurrencies,
} = currencies.actions;

export default currencies.reducer;
