import { createSlice } from "@reduxjs/toolkit";

export const notifications = createSlice({
  name: "notifications",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Set notifications
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setNotifications: (state, action) => {
      state.init = true;
      state.list = action?.payload;
    },
  },
});

export const {
  setNotifications,
} = notifications.actions;

export default notifications.reducer;
