import { createSlice } from "@reduxjs/toolkit";

export const tags = createSlice({
  name: "tags",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init tags
     * @param {ReduxState} state
     * @return {void}
     */
    initTags: (state) => {
      state.init = true;
    },
    /**
     * Deinit tags
     * @param {ReduxState} state
     * @return {void}
     */
    deinitTags: (state) => {
      state.init = false;
    },
    /**
     * Set tags
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setTags: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initTags,
  deinitTags,
  setTags,
} = tags.actions;

export default tags.reducer;
