import { createSlice } from "@reduxjs/toolkit";

export const subscription = createSlice({
  name: "subscription",
  initialState: {
    init: false,
    _id: null,
    account: null,
    type: null,
    endPlanAt: null,
    maxRessources: null,
    maxWorkspaces: null,
    chargebeeId: null,
  },
  reducers: {
    /**
     * Init subscription
     * @param {ReduxState} state
     * @return {void}
     */
    initSubscription: (state) => {
      state.init = true;
    },
    /**
     * Deinit subscription
     * @param {ReduxState} state
     * @return {void}
     */
    deinitSubscription: (state) => {
      state.init = false;
    },
    /**
     * Set subscription
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setSubscription: (state, action) => {
      state._id = action?.payload?._id;
      state.account = action?.payload?.account;
      state.type = action?.payload?.type;
      state.endPlanAt = action?.payload?.endPlanAt;
      state.maxRessources = action?.payload?.maxRessources;
      state.maxWorkspaces = action?.payload?.maxWorkspaces;
      state.chargebeeId = action?.payload?.chargebeeId;
    },
  },
});

export const {
  initSubscription,
  deinitSubscription,
  setSubscription,
} = subscription.actions;

export default subscription.reducer;
