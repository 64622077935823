import { createSlice } from "@reduxjs/toolkit";

export const app = createSlice({
  name: "app",
  initialState: {
    loader: false,
    darkMode: localStorage.getItem("darkMode") ?
      localStorage.getItem("darkMode") === "true" ? true : false : false,
  },
  reducers: {
    /**
     * Show loader
     * @param {ReduxState} state
     * @return {void}
     */
    showLoader: (state) => {
      state.loader = true;
    },
    /**
     * Hide loader
     * @param {ReduxState} state
     */
    hideLoader: (state) => {
      state.loader = false;
    },
    /**
     * set Dark mode
     * @param {ReduxState} state
     * @param {Action} action
     */
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
  },
});

export const {
  showLoader,
  hideLoader,
  setDarkMode,
} = app.actions;

export default app.reducer;
