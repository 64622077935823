import { createSlice } from "@reduxjs/toolkit";

export const mytasks = createSlice({
  name: "mytasks",
  initialState: {
    init: false,
    data: {},
  },
  reducers: {
    /**
     * Init My tasks
     * @param {ReduxState} state
     * @return {void}
     */
    initMyTasks: (state) => {
      state.init = true;
    },
    /**
     * Deinit My tasks
     * @param {ReduxState} state
     * @return {void}
     */
    deinitMyTasks: (state) => {
      state.init = false;
    },
    /**
     *  Set My tasks
     * @param {ReduxState} state
     * @param {Action} action
     */
    setMyTasks: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  initMyTasks,
  deinitMyTasks,
  setMyTasks,
} = mytasks.actions;

export default mytasks.reducer;
