import { createSlice } from "@reduxjs/toolkit";

export const users = createSlice({
  name: "users",
  initialState: {
    list: [],
  },
  reducers: {
    /**
     * Set users
     * @param {ReduxState} state
     * @param {Action} action
     */
    setUsers: (state, action) => {
      state.list = action?.payload;
    },
  },
});

export const {
  setUsers,
} = users.actions;

export default users.reducer;
