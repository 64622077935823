/* eslint-disable max-len */
import "./index.scss";
import logo from "../../images/logo-icon.svg";
import { Divider, Typography } from "@mui/material";

/**
 * PrivacyPolicy component
 * @constructor
 */
const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy">
      <img src={logo} className="logo" alt="logo" />
      <Typography variant="h5">Replan Privacy Policy</Typography>
      <Divider />
      <br/>
      <p className="bold">
        By using Replan application you agree to be bound by the privacy policy below reproduced.
      </p>
      <p>
          Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your personal data in accordance with our website
      </p>

      <ul>
        <li>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, VAT Number or other details to help you with your experience.</li>

        <li>We collect information from you when you register on our application, place an order or enter information on our site. Personal data is collected when the data subject authorizes it and the disclosure of such data implies the knowledge and acceptance of the provisions set forth in this Privacy Policy. Hence, by providing personal data, the User authorizes the collection, use or disclosure of such data in accordance with the rules defined in the applicable collection tools.</li>

        <li>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features.</li>

        <li>We may disclose personal data in compliance with a court order or an order from another competent authority and under the Law in force.</li>

        <li>We guarantee safety and protection of personal data, safeguarding the protection of the personal data provided by its Clients and/or Users, against their abusive use or unauthorized access.</li>

        <li>We do not sell, trade, or otherwise, transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</li>

        <li>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</li>

        <li>Transaction data, in the mentioned personal data, can be transferred for the benefit of Stripe (http://www.stripe.com), on the terms that it will beneficial on in-service connected to order payments. A client has the right to see the content of his data and any corrections made to it. Sharing of information is voluntary, however, at the same time, it is crucial to use this service.</li>

        <li>The rules of this Privacy Policy complement the provisions related to personal data of the terms and conditions of use of the planless.io website and application.</li>

        <li>We reserve the right to amend this Privacy Policy at any time. Any amendments will be published in this Privacy Policy and the material changes shall apply to the activities and data collected in the future.</li>

        <li>
          <p>In accordance with the Data Protection Law, the Client or User may contact Planless LDA in case of any doubt on the Privacy Policy or to amend, correct or eliminate personal data, by using the following contacts:</p>
          <p/>
              Planless LDA<br/>
              Replan Customer support<br/>
              Avenida David Mourão-Ferreira 14 8A<br/>
              1750-204 Lisboa - Portugal
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
