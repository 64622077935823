import { createSlice } from "@reduxjs/toolkit";

export const workspace = createSlice({
  name: "workspace",
  initialState: {
    _id: null,
    isMaster: false,
    account: null,
    name: null,
    ressources: [],
    skillProfiles: [],
    skills: [],
    packages: [],
    projects: [],
    tags: [],
    expenseTypes: [],
    globalTimeOffs: [],
    defaultCurrency: null,
    globalExpenses: [],
    timezone: null,
    baseSchedule: null,
  },
  reducers: {
    /**
     * Set workspace data
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setWorkspaceData: (state, action) => {
      state._id = action?.payload?._id;
      state.isMaster = action?.payload?.isMaster;
      state.account = action?.payload?.account;
      state.name = action?.payload?.name;
      state.ressources = action?.payload?.ressources;
      state.skillProfiles = action?.payload?.skillProfiles;
      state.skills = action?.payload?.skills;
      state.packages = action?.payload?.packages;
      state.projects = action?.payload?.projects;
      state.tags = action?.payload?.tags;
      state.expenseTypes = action?.payload?.expenseTypes;
      state.globalTimeOffs = action?.payload?.globalTimeOffs;
      state.defaultCurrency = action?.payload?.defaultCurrency;
      state.globalExpenses = action?.payload?.globalExpenses;
      state.timezone = action?.payload?.timezone;
      state.baseSchedule = action?.payload?.baseSchedule;
    },
  },
});

export const {
  setWorkspaceData,
} = workspace.actions;

export default workspace.reducer;
