import { createSlice } from "@reduxjs/toolkit";

export const user = createSlice({
  name: "user",
  initialState: {
    init: false,
    _id: null,
    account: null,
    uid: null,
    email: null,
    permissions: null,
    workspaces: [],
    activeWorkspace: null,
    dateFormat: "DD/MM/YYYY",
    timeFormat: "24h",
    authType: null,
    requestEffortUpdateOn: [],
  },
  reducers: {
    /**
     * Init user
     * @param {ReduxState} state
     * @return {void}
     */
    initialize: (state) => {
      state.init = true;
    },
    /**
     * Deinit user
     * @param {ReduxState} state
     * @return {void}
     */
    deinitialize: (state) => {
      state.init = false;
    },
    /**
     * Set user
     * @param {ReduxState} state
     * @param {Action} action
     */
    setUser: (state, action) => {
      state._id = action?.payload?._id;
      state.account = action?.payload?.account;
      state.uid = action?.payload?.uid;
      state.email = action?.payload?.email;
      state.permissions = action?.payload?.permissions;
      state.workspaces = action?.payload?.workspaces;
      state.activeWorkspace = action?.payload?.activeWorkspace;
      state.dateFormat = action?.payload?.dateFormat;
      state.timeFormat = action?.payload?.timeFormat;
      state.authType = action?.payload?.authType;
      state.requestEffortUpdateOn = action?.payload?.requestEffortUpdateOn;
    },
    /**
     * Set date format
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setDateFormat: (state, action) => {
      state.dateFormat = action?.payload;
    },
    /**
     * Set time format
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setTimeFormat: (state, action) => {
      state.timeFormat = action?.payload;
    },
  },
});

export const {
  setUser,
  initialize,
  deinitialize,
  setDateFormat,
  setTimeFormat,
} = user.actions;

export default user.reducer;
