import { createSlice } from "@reduxjs/toolkit";

export const packages = createSlice({
  name: "packages",
  initialState: {
    init: false,
    list: [],
  },
  reducers: {
    /**
     * Init packages
     * @param {ReduxState} state
     * @return {void}
     */
    initPackages: (state) => {
      state.init = true;
    },
    /**
     * Deinit packages
     * @param {ReduxState} state
     * @return {void}
     */
    deinitPackages: (state) => {
      state.init = false;
    },
    /**
     * Set packages
     * @param {ReduxState} state
     * @param {Action} action
     * @return {void}
     */
    setPackages: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const {
  initPackages,
  deinitPackages,
  setPackages,
} = packages.actions;

export default packages.reducer;
