import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user";
import usersReducer from "./reducers/users";
import appReducer from "./reducers/app";
import workspaceReducer from "./reducers/workspace";
import subscriptionReducer from "./reducers/subscription";
import currenciesReducer from "./reducers/currencies";
import notificationsReducer from "./reducers/notifications";
import skillProfilesReducer from "./reducers/skillProfiles";
import skillsReducer from "./reducers/skills";
import expenseTypesReducer from "./reducers/expenseTypes";
import tagsReducer from "./reducers/tags";
import packagesReducer from "./reducers/packages";
import projectsReducer from "./reducers/projects";
import mytasksReducer from "./reducers/mytasks";

export const store = configureStore({
  reducer: {
    app: appReducer,
    subscription: subscriptionReducer,
    user: userReducer,
    users: usersReducer,
    workspace: workspaceReducer,
    currencies: currenciesReducer,
    notifications: notificationsReducer,
    skillProfiles: skillProfilesReducer,
    skills: skillsReducer,
    expenseTypes: expenseTypesReducer,
    tags: tagsReducer,
    packages: packagesReducer,
    projects: projectsReducer,
    mytasks: mytasksReducer,
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch
